
  .login-container {
      -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,.15);
      box-shadow: 0 2px 10px 0 rgba(0,0,0,.15);
      border-radius: 6px;
      overflow: hidden;
      margin: 40px auto 10px;
      max-width: 980px;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: center;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
    align-items: stretch;
    
  }
  .login-container .login-info {
      color: #fff;
      padding: 10% 0 0 45px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      /*justify-content: space-between;*/
      -ms-flex-direction: column;
      flex-direction: column;
      font-size: 18px;
    width: 60%;
    background: url(/static/images/pornoreino-community.jpg) center center no-repeat;
    background-size: cover;
  }
  .login-container .login-form{
    width: 40%;
    background: #202020;
    padding: 30px 50px;
  }
  
  .login-container .logo{
    height: 50px;
    text-align: center;
    border-bottom: 2px solid #f4f4f480;
    padding-bottom: 3.5rem;  
  }
  
  .login-container .logo img{
    height: 50px;
  }
  
  .login-container .switch{
    background: #e60012;
  }
  
  .login-container .login-form h2{
    color: #f1f1f1;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 1.5rem;
    border-bottom: 2px solid #f4f4f480;
    padding-bottom: .2rem;  
  }
  .login-container .login-info h1{
    flex: inherit;
    font-size: 3.5rem;
  }
  
  .login-container .textbox{
    width: 100%;
    height: 50px;
    position: relative;
    margin-top: 15px;
  }
  
  .login-container .textbox input{
    width: 100%;
    height: 50px;
    border: none;
    background: #2b2b2b;
    padding: 0 15px;
    font-size: 16px;
    outline: none;
    color: #f4f4f4;
  }
  
  .login-container .textbox input:focus{
    background: #484848 !important;
  }
  
  .login-container .check-message{
    position: absolute;
    top: 50%;
    right: 10px;
    color: #fff;
    text-transform: uppercase;
    transform: translateY(-50%);
  }
  
  .login-container .textbox input:focus + .check-message{
    display: none;
  }
  
  .login-container .options{
    margin-top: 15px;
    color: #f4f4f480;
    overflow: hidden;
    font-size: 14px;
  }
  
  .login-container .remember-me{
    float: left;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .login-container .checkbox{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #484848;
    margin-right: 15px;
    position: relative;
  }
  
  .login-container .checkbox input{
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .login-container .checked{
    position: absolute;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    display: none;
  }
  
  .login-container .checkbox input:checked + .checked{
    display: block;
  }
  
  .login-container .options a{
    color: #f4f4f480;
    font-size: 14px;
    float: right;
  }
  
  .login-container .login-btn{
    width: 100%;
    height: 50px;
    margin-top: 30px;
    background: #191919;
    border: none;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    transition: .3s linear;
  }
  
  .login-container .login-btn.active{
    background: rgb(248, 117, 1);
    color: #eee;
  }
  
  .login-container .login-btn.active:hover{
    opacity: .7;
  }
  
  .login-container .privacy-link{
    text-align: center;
    margin-top: 35px;
  }
  
  .login-container .privacy-link a{
    color: #f1f1f1;
    font-size: 14px;
  }
  
  .login-container .dont-have-account{
    font-size: 14px;
    text-align: center;
    color: #f4f4f480;
    margin: 20px 0;
  }
  
  .login-container .dont-have-account a{
    color: #f1f1f1;
  }
  .login-container .login-btn:disabled {
      color: #6b6b6b;
  }
  .login-container .features {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    padding-top: 20px;
  }
  .login-container .features li {
    flex-basis: 50%;
    padding-right: 12px;
    font-size: 16px;
    margin: 0 0 10px;
    display: flex;
    justify-content: flex-start;
  }
  .login-container .features svg {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.2rem;
  }
  .login-container .login-message{
    display: flex;
    margin: 30px 0;
  }  
  .login-form .generic-error {
    margin-top: 5px;
    font-weight: inherit;
  }  
  @media screen and (max-width:768px) {
    .login-container .login-form{
      width: 80%;
      padding: 0 15px;
      display: inline-block;
    }
    .login-container .login-info {
      width: 80%;
      padding: 10px 0 0 55px
    }
    .login-container{
          flex-wrap: wrap;
          margin: 10px auto 10px;
     }
  @media screen and (max-width:470px) {
    body{
      background: #202020;
    }
  
    .login-container .login-form{
      width: 100%;
      padding: 0 15px;
      display: inline-block;
    }
    .login-container .login-info {
      width: 100%;
      padding: 10px 0 0 15px;
      text-align: center;
    }
    .login-container{
          flex-wrap: wrap;
     }
     .login-container .login-info h1 {
        font-size: 2.8rem;
        text-align: center;
    } 
    .login-container .privacy-link {
        display: none;
    }        
  }
}  
