.btn-g {
    border: none;
    background: none;
    -webkit-transition: background .25s ease-out;
    transition: background .25s ease-out;
    cursor: pointer;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.btn-send {
    width: 120px;
}
.btn-red {
    background: #fe5461;
    color: #fff !important;
}
.btn-red:hover {
    opacity: 0.9;
}
.btn-orange {
    background: #f87501;
    color: #fff !important;
}
.btn-orange:hover {
    opacity: 0.9;
}
.btn-blue {
    background: #4c84ff;
    color: #fff !important;
}
.btn-blue:hover {
    opacity: 0.9;
}
.btn-white {
    background: #fff;
}
.btn-silver {
    background: #eee;
}
.btn-stone-disabled {
    background: #aaa69d;
    cursor: not-allowed;
}
.btn-block {
    width: 100%;
}
.btn-accept {
    background: #00B16A;
}
.btn-deny {
    background: #D91E18;
}
.member-content .selected a {
    color: #eee;
}
.member-page > .right .member-content > .nav > li:not(.selected) a {
    color: #8c919d;
}
.member-content .list-albums .item {
    width: 19%!important;
}
#button-g [class^="ri-"] {
    padding-right: .4rem;
}
.friend_reply {
    padding-top: .5rem;
}
input.friend-respond.btn-deny,
input.friend-respond.btn-accept {
    padding: 0 20px;
    width: 49%;
    border: none;
    -webkit-transition: background .25s ease-out;
    transition: background .25s ease-out;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    height: 25px;
}
.content-block {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}
.member-content section[class*="section-"] .headline {
    padding: 1rem 0 0;
}
.member-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 24px;
}
@media (max-width: 1024px) {
    .member-page {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .friend_reply {
        padding-top: 0;
        padding-left: .4rem;
    }  
    input.friend-respond.btn-deny,
    input.friend-respond.btn-accept {
        height: 40px;
    }      
}
.member-page > .left {
    width: 216px;
    min-width: 216px;
}
.member-page > .left .info-block .member-dropdown .dropdown-button {
    width: 25px;
    height: 35px;
    line-height: 1;
    color: #fe5461;
}
.member-page > .left .info-block .member-dropdown .dropdown-content {
    color: #fe5461;
}
@media (max-width: 1024px) {
    .member-page > .left {
        width: 100%;
        min-width: 100%;
    }
}
.member-page .cover {
    height: 210px;
    width: 100%;
    background-color: rgba(37, 42, 53, 0.46);
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    position: relative;
}
.member-page .cover:hover .upload {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.member-page .cover .upload {
    position: absolute;
    top: 12px;
    right: 12px;
    height: 26px;
    width: 26px;
    background: #262626;
    opacity: 0.8;
    color: #fff;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
}
.member-page .cover .upload:hover {
    opacity: 1;
}
@media (max-width: 1024px) {
    .member-page .cover {
        display: none;
    }
}
.member-page .cover--mobile {
    display: none;
}
@media (max-width: 1024px) {
    .member-page .cover--mobile {
        display: block;
        margin: 0 -10px;
        margin-bottom: 0;
        width: calc(100% + 20px);
        height: 100px;
        background-color: rgba(37, 42, 53, 0.46);
    }
}
.member-page > .right {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 30px;
}
@media (max-width: 1024px) {
    .member-page > .right {
        padding-left: 0;
    }
}
.member-page > .right .member-content > .nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    list-style: none;
    display: block;
}
@media (max-width: 1024px) {
    .member-page > .right .member-content > .nav {
        white-space: nowrap;
        overflow-x: auto;
    }
}
.member-page > .right .member-content > .nav > li {
    margin-left: 20px;
    padding-bottom: 12px;
    cursor: pointer;
    color: #8c919d;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    display: inline-block;
}
.member-page > .right .member-content > .nav > li:not(.dropdown):hover {
    opacity: 0.8;
}
.member-page > .right .member-content > .nav > li.selected {
    border-bottom: 3px solid #ff793f;
    color: #eee;
}
.member-page > .right .member-content > .nav > li:first-child {
    margin-left: 0;
}
.member-page > .right .member-content > .nav .subscribe {
    float: right;
}
@media (max-width: 1024px) {
    .member-page > .right .member-content > .nav .subscribe {
        float: none;
    }
}
.member-page > .right .member-content > .nav .subscribe .btn-g {
    height: 32px;
    width: 120px;
}
.member-page > .right .member-content > .nav .empty {
    height: 16px;
    margin: 12px 0;
    width: 100px;
}
.member-page > .right .member-content > .listing {
    margin-top: 20px;
}
.member-page > .right .member-content section[class*="section-"] {
    margin-top: 10px;
}
@media (max-width: 736px) {
    .member-page > .right .member-content section[class*="section-"]  .listing__content .item-video {
        min-width: 100%;
        max-width: 100%;
    }
}
.member-page > .right .member-content .section-Playlists {
    margin-top: 10px;
}
.member-page > .right .member-content .section-Playlists .listing__content .item-playlist {
    min-width: 25%;
    max-width: 25%;
}
.member-page > .right .member-content .section-Members {
    margin-top: 10px;
}
@media (max-width: 1024px) {
    .member-page > .right .member-content .section-Members .listing__content .item-member {
        min-width: 25%;
        max-width: 25%;
    }
}
@media (max-width: 736px) {
    .member-page > .right .member-content .section-Members .listing__content .item-member {
        min-width: 50%;
        max-width: 50%;
    }
}
.member-page > .right .member-content .section-comments {
    margin-top: 20px;
}
.member-page > .right .member-content .section-comments .comments {
    margin-bottom: 20px;
}
.member-page > .right .member-edit {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.member-page > .right .member-edit__wrapper {
    max-width: 1164px;
    margin: 0 auto;
}
.member-page > .right .member-edit__wrapper .nav {
    margin-bottom: 26px;
}
.member-page > .right .member-edit__back {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #eee;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 24px;
}
.member-page .right .member-edit__back .ri-arrow-left-line {
    margin-right: 8px;
}
.member-page > .right .member-edit__col {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 32px 36px;
    background: #111;
}
@media (max-width: 1024px) {
    .member-page > .right .member-edit__col {
        display: none;
        padding: 20px 16px;
    }
}
@media (max-width: 1024px) {
    .member-page > .right .member-edit__col--active {
        display: block;
    }
}
.member-page > .right .member-edit__col:first-child {
    margin-right: 20px;
}
@media (max-width: 1024px) {
    .member-page > .right .member-edit__col:first-child {
        margin-right: 0;
    }
}
.member-page > .right .member-edit__col > .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
}
.member-page > .right .member-edit__col .btn-red,
.member-page > .right .member-edit__col .btn-orange {
    min-width: 120px;
}
.member-page > .right .member-messages {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-right: 1px solid rgba(25, 35, 59, 0.1);
    border-left: 1px solid rgba(25, 35, 59, 0.1);
    max-width: 900px;
    margin: 0 auto;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages {
        border: none;
        margin-bottom: 20px;
    }
}
.member-page > .right .member-messages > .messages-title {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
}
.member-page > .right .member-messages .messages {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: 100vh;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .messages {
        max-height: 90vh;
    }
}
.member-page > .right .member-messages .messages-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 32px 24px;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .messages-top {
        padding: 16px 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-bottom: 1px solid rgba(25, 35, 59, 0.1);
        margin-bottom: 16px;
    }
}
.member-page > .right .member-messages .messages-top .avatar,
.member-page > .right .member-messages .messages-top .no-avatar {
    background-color: #fff;
}
.member-page > .right .member-messages .messages-top > .username {
    font-size: 14px;
    line-height: 20px;
    color: #19233b;
}
.member-page > .right .member-messages .messages-top .back {
    display: none;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .messages-top .back {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-right: 12px;
        padding: 4px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}
.member-page > .right .member-messages .messages-top .avatar__wrapper {
    display: none;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .messages-top .avatar__wrapper {
        display: block;
        margin-right: 12px;
    }
}
.member-page > .right .member-messages .messages-list {
    overflow-y: auto;
    padding: 0 24px;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .messages-list {
        padding: 0;
    }
}
.member-page > .right .member-messages .messages-list::-webkit-scrollbar {
    width: 5px;
    background: var(--primary);
}
.member-page > .right .member-messages .messages-list::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 2px;
}
.member-page > .right .member-messages .messages-list__group > .date {
    font-size: 12px;
    color: #babdc4;
    text-align: center;
    margin-bottom: 15px;
}
.member-page > .right .member-messages .messages-list__group > .message {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0 6px;
}
.member-page > .right .member-messages .messages-list__group > .message.self {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.member-page > .right .member-messages .messages-list__group > .message.self > .block > .time {
    text-align: right;
}
.member-page > .right .member-messages .messages-list__group > .message:not(.self) > .block {
    -webkit-box-shadow: 0 4px 20px rgba(25, 35, 59, 0.03);
    box-shadow: 0 4px 20px rgba(25, 35, 59, 0.03);
    background: #fff;
}
.member-page > .right .member-messages .messages-list__group > .message > .block {
    max-width: 315px;
    padding: 16px 20px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background: rgba(76, 132, 255, 0.1);
    color: #5e6576;
    -webkit-box-align: top;
    -ms-flex-align: top;
    align-items: top;
}
.member-page > .right .member-messages .messages-list__group > .message > .block .block-text {
    word-wrap: break-word;
    min-width: 0;
}
.member-page > .right .member-messages .messages-list__group > .message > .block > .time {
    display: block;
    color: #8c919d;
    font-size: 12px;
    margin-left: 24px;
}
.member-page > .right .member-messages .messages-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 16px 0;
    padding: 0 24px;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .messages-bottom {
        padding: 0;
    }
}
.member-page > .right .member-messages .messages-bottom > input {
    border: none;
    background: #fff;
    height: 40px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 16px;
    padding-right: 40px;
}
.member-page > .right .member-messages .messages-bottom > button {
    border: none;
    color: #fe5461;
    cursor: pointer;
    position: absolute;
    right: 40px;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .messages-bottom > button {
        right: 12px;
    }
}
.member-page > .right .member-messages .messages-bottom > button svg {
    vertical-align: middle;
}
.member-page > .right .member-messages .dialogs {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-right: 1px solid rgba(25, 35, 59, 0.1);
    max-width: 400px;
    min-height: calc(100vh - 324px);
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .dialogs {
        max-width: none;
        width: 100%;
    }
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .dialogs--open {
        display: none;
    }
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .dialogs {
        border: none;
    }
}
.member-page > .right .member-messages .dialogs-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 32px;
    border-bottom: 1px solid rgba(25, 35, 59, 0.1);
}
.member-page > .right .member-messages .dialogs-header .title {
    margin-bottom: 0;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .dialogs-header {
        border: none;
        padding: 0 0 20px 0;
    }
}
.member-page > .right .member-messages .dialogs-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .dialogs-list {
        background: #fff;
        padding: 8px 0;
    }
}
.member-page > .right .member-messages .dialogs-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    padding: 0 32px;
    position: relative;
    cursor: pointer;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .dialogs-item {
        border: none;
        padding: 0 16px;
    }
}
.member-page > .right .member-messages .dialogs-item:hover {
    background: #fff;
}
.member-page > .right .member-messages .dialogs-item.no-read:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 40%;
    right: 26px;
    background: #fe5461;
}
.member-page > .right .member-messages .dialogs-item > .left {
    max-width: 40px;
}
.member-page > .right .member-messages .dialogs-item > .left > .avatar .img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #babdc4;
    background-size: cover;
}
.member-page > .right .member-messages .dialogs-item > .right {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 10px;
    overflow: hidden;
}
@media (max-width: 1024px) {
    .member-page > .right .member-messages .dialogs-item > .right {
        padding-left: 16px;
    }
}
.member-page > .right .member-messages .dialogs-item > .right > .top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 3px;
}
.member-page > .right .member-messages .dialogs-item > .right > .top > .username {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #19233b;
}
.member-page > .right .member-messages .dialogs-item > .right > .top > .date {
    font-size: 10px;
    line-height: 20px;
    color: #babdc4;
    margin-left: 12px;
}
.member-page > .right .member-messages .dialogs-item > .right > .bottom {
    padding-right: 20px;
    color: #5e6576;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
}
.message-page {
    padding-top: 0;
    margin-bottom: 0;
}
@media (min-width: 1024px) and (max-width: 1366px) {
    .member-page .adaptive-nav > li:nth-child(5),
    .member-page .adaptive-nav > li:nth-child(6),
    .member-page .adaptive-nav > li:nth-child(7),
    .member-page .adaptive-nav > li:nth-child(8) {
        display: none !important;
    }
    .member-page .adaptive-nav > li:nth-child(9) {
        display: inline-block !important;
    }
    .member-page .adaptive-nav > li:nth-child(9) .dropdown-button i {
        vertical-align: sub;
    }
    .member-page .adaptive-nav .dropdown-content li {
        padding-left: 28px;
        padding-right: 40px;
    }
    .listing-playlists .listing__content,
    .listing-videos .listing__content {
        grid-template-columns: repeat(3, 33.33%) !important;
    }
    .listing-members .listing__content {
        grid-template-columns: repeat(4, 25%) !important;
    }
}
.info-block {
    -webkit-box-shadow: 0 4px 20px rgba(25, 35, 59, 0.03);
    box-shadow: 0 4px 20px rgba(25, 35, 59, 0.03);
    margin-bottom: 8px;
    background: #111;
    padding-top: 16px;
    position: relative;
}
@media (max-width: 1024px) {
    .info-block--overlay {
        margin-top: -50px;
    }
}
@media (max-width: 1024px) {
    .info-block {
        background: transparent;
        padding-top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}
.info-block-share {
    display: none;
    position: absolute;
    height: 30px;
    width: 30px;
    top: 10px;
    right: 10px;
    font-size: 18px;
    text-align: center;
    border-radius: 50%;
    color: var(--secondary-text);
    cursor: pointer;
}
.info-block-share i {
    line-height: 30px;
}
.info-block-share:hover {
    color: var(--primary-text);
    background: var(--secondary);
}
.info-block-avatar {
    margin-top: 8px;
}
@media (max-width: 1024px) {
    .info-block-avatar {
        height: 100px;
        width: 100px;
        background: #84817a;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-shadow: 0 4px 2px rgba(25, 35, 59, 0.02);
        box-shadow: 0 4px 2px rgba(25, 35, 59, 0.02);
        margin: 0 auto;
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }
}
.info-block-avatar .img {
    background-color: #111;
    background-size: cover;
    width: 100%;
    height: 180px;
    position: relative;
}
@media (max-width: 1024px) {
    .info-block-avatar .img {
        height: 92px;
        width: 92px;
    }
}
.info-block-avatar .img:hover .upload {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.info-block-avatar .img .upload {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 26px;
    width: 26px;
    background: #262626;
    opacity: 0.8;
    color: #fff;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
}
.info-block-avatar .img .upload:hover {
    opacity: 1;
}
@media (max-width: 1024px) {
    .info-block-avatar .img .upload {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        right: 0;
        bottom: 0;
        top: auto;
    }
}
.info-block-name {
    font-size: 1.8rem;
    line-height: 24px;
    font-weight: 500;
    color: #eee;
    padding: 0 16px;
    margin-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.info-block-name .empty {
    height: 24px;
    width: 50%;
    border-radius: 4px;
    margin: 0 auto;
}
@media (max-width: 1024px) {
    .info-block-name {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-top: 16px;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px;
    }
}
.info-block-buttons {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
@media (max-width: 1024px) {
    .info-block-buttons {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        margin-top: 16px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        width: 100%;
        max-width: 400px;
    }
}
.info-block-buttons .btn {
    color: #8c919d;
}
@media (max-width: 1024px) {
    .info-block-buttons .btn {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
}
.info-block-buttons .empty {
    height: 30px;
    width: 40%;
    border-radius: 4px;
    margin: 0 auto;
}
.info-block-buttons .dropdown-button:after {
    display: none;
}
.info-block-buttons .send {
    padding: 0 20px;
}
.info-block-stats {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 16px;
}
@media (max-width: 1024px) {
    .info-block-stats {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
}
.info-block-stats .stats-item {
    color: #eee;
    font-size: 13px;
    text-align: center;
    line-height: 15px;
    margin-right: 1.8rem;
    cursor: pointer;
}
.info-block-stats .stats-item > .empty {
    width: 75%;
    height: 15px;
    border-radius: 4px;
    margin: 0 auto;
}
.info-block-stats .stats-item .count {
    font-size: 12px;
    line-height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.info-block-stats .stats-item .count i {
    color: #babdc4;
    margin-right: 4px;
    font-size: 16px;
}
.info-block-stats .stats-item .count .ri-eye-line {
    font-size: 14px;
}
.info-block-stats .stats-item .count > .empty {
    width: 40%;
    height: 18px;
    border-radius: 4px;
    margin: 0 auto;
}
.info-block-tags {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 20px;
}
.info-block-tags .empty {
    width: 100px;
}
.info-block-rating {
    border-top: 1px solid var(--line);
    border-bottom: 1px solid var(--line);
    padding: 10px 0;
    margin-top: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.info-block-rating .comments,
.info-block-rating .dislike,
.info-block-rating .like {
    padding: 0 10px;
    margin: 0 10px;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    color: var(--secondary-text);
}
.info-block-rating .comments i,
.info-block-rating .dislike i,
.info-block-rating .like i {
    display: block;
    font-size: 15px;
    margin-bottom: 5px;
}
.info-block-rating .like:hover {
    color: #27ae60;
}
.info-block-rating .dislike:hover {
    color: #fe5461;
}
.info-block-rating .comments:hover {
    color: var(--primary-text);
}
.info-block-rating .empty {
    width: 10%;
    height: 36px;
    margin: 0 10px;
    border-radius: 5px;
}
.info-block-list {
    list-style: none;
    padding: 0 16px 32px;
}
.info-block-list-description {
    margin-top: 12px !important;
}
.info-block-list li {
    margin-top: 8px;
    color: #DADFE1;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
}
.info-block-list li > .title {
    color: #ff793f;
    font-size: 14px;
    line-height: 20px;
    margin-right: 5px;
    margin-bottom: 0;
    -ms-flex-item-align: baseline;
    align-self: baseline;
    white-space: nowrap;
}
.info-block-list li .empty {
    width: 75%;
    height: 19px;
}
.info-block-list li i {
    display: inline-block;
    vertical-align: sub;
    margin: 0 5px 2px;
}

.main-container-user {
    width: 100%; 
}
@media (max-width: 736px) {
    .member-page .sort {
        display: none;
    }
}
.no-avatar {
    position: relative;
    background: #f5f6fa;
}
.no-avatar:after {
    position: absolute;
    content: attr(title);
    color: #babdc4;
    text-transform: uppercase;
    top: 50%;
    left: 50%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 21px;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
}
.events {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.events > .comments-title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #19233b;
    margin-bottom: 24px;
}
.events .item-avatar {
    width: 40px;
}
.events .item-avatar .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.events .item-avatar .avatar .img {
    height: inherit;
    background-size: cover;
}
.events .item-avatar .no-avatar {
    background: #fff;
}
.events > .items .event {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
}
.events > .items .event.underrate {
    opacity: 0.6;
}
.events > .items .event-content {
    padding-left: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.events > .items .event-content .username {
    font-size: 14px;
    color: #ff793f;
    line-height: 20px;
}
.events > .items .event-content .username--light {
    color: #8c919d;
}
.events > .items .event-content .username .date {
    font-size: 10px;
    color: #babdc4;
    margin-left: 5px;
    display: inline-block;
}
.events > .items .event-content .comment {
    color: #BFBFBF;
    font-size: 15px;
    line-height: 20px;
}
.events .event a {
    color: #eee;
}
.events h1 {
    line-height: 20px;
    font-weight: 500;
    color: #eee;
    font-size: 14px;
}

.mobile {
    display: none;
}
@media (max-width: 1024px) {
    .mobile {
        display: block;
    }
}
@media (max-width: 1024px) {
    .desktop {
        display: none;
    }
}
/*edit profile*/
.member-page .input {
    border: 1px solid rgba(25,35,59,.1);
    padding: 0 16px;
    color: #111;
    height: 40px;
    font-size: 14px;
    border-radius: 0;
    font-family: 'Lato', sans-serif;
}
.member-page .form-row label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #eee;
    margin-bottom: 8px;
    display: block;
}

.member-page .form-row {
    width: 100%;
    margin-top: 8px;
}

.member-page .form :first-child {
    margin-top: 0;
}
.member-page .form-row>.input {
    width: 100%;
    background: #ECF0F1;
}
.member-page .form .select {
    height: 40px;
}
.member-page .select {
    position: relative;
    border: 1px solid rgba(25,35,59,.1);
    color: #111 ;
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
}

.select__label, .select__label .label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.member-page .select__label {
    height: 40px;
    padding: 0 10px;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #111;
    width: 100%;
}
.select__label .label {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.member-page .select-bday {
    position: relative;
    border: 1px solid rgba(25,35,59,.1);
    color: #111;
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    padding: 0 10px;
}
.member-page textarea {
    border: 1px solid rgba(25,35,59,.1);
    padding: 10px;
    outline: none;
    font-family: inherit;
    font-size: 15px;
    overflow: hidden;
    color: #eee;
    resize: none;
    border-radius: 0;
    -webkit-appearance: none;
}

.member-page form .smileys-support .smileys-bar,
.member-page form .smileys-support .textarea {
    border-radius: 0;
}

.text_area {
    position: relative;
}

.text_area .counter {
    position: absolute;
    bottom: 8px;
    right: 8px;
    color: #babdc4;
    font-size: 14px;
}