/* default theme*/
.two-sections {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.file-control input.textfield {
    border-radius: initial;
}
.section-one .file-control .button {
    border-radius: 5px;
}
.row.upload .input::-webkit-input-placeholder,
.form-row.upload input::-webkit-input-placeholder{
    padding-left: 5.5rem;
  }
.row.upload input::-moz-placeholder,
.form-row.upload input::-moz-placeholder{
padding-left: 5.5rem;
}
.row.upload input:-ms-input-placeholder,
.form-row.upload input:-ms-input-placeholder{
padding-left: 5.5rem;
}
.row.upload input::placeholder,
.form-row.upload input::placeholder{
padding-left: 5.5rem;
}
.two-sections .textfield {
    border-radius: inherit;
}
.box .btn-orange {
    min-width: 210px;
}    
.two-sections.details {
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: normal;
}
.form-row.description {
    position: relative;
}
.upload-page form .section-one .textarea {
    resize: none;
    min-height: 120px;
    height: auto;
    background: none;
    border: none;
    border: 1px solid rgb(41, 43, 49);
    padding: 10px;
    outline: none;
    font-family: inherit;
    font-size: 15px;
    overflow: hidden;
    border-radius: inherit;
    color: #eee;
    background: #2e2e2e;
}
.upload-page form .section-one .counter {
    position: absolute;
    bottom: 8px;
    right: 8px;
    color: #babdc4;
    font-size: 14px;
}
form .file-control .button.screenshot {
    padding: 6px 10px;
}
/*new form*/
.upload-page .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1rem 0 0;
}
.upload-page-start-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media (max-width: 1024px) {
    .upload-page-start-block {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}
.upload-page-start-block-left {
    width: 75%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 40px 0 60px;
    background: #111;
}
.upload-page-start-block-left-drop {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #eee;
}
@media (max-width: 1024px) {
    .upload-page-start-block-left-drop {
        display: none;
    }
}
.upload-page-start-block-left-button {
    margin-top: 8px;
    color: #8c919d;
}
@media (max-width: 1024px) {
    .upload-page-start-block-left-button {
        display: none;
    }
}
.upload-page-start-block-left-button--mobile {
    display: none;
}
@media (max-width: 1024px) {
    .upload-page-start-block-left-button--mobile {
        display: block;
    }
}
.upload-page-start-block-left-button--mobile .btn-orange {
    width: 140px;
}
.upload-page-start-block-left-weblink {
    margin-top: 15px;
}
.upload-page-start-block-left-weblink .input {
    text-align: center;
    background: none;
    height: 35px;
}
.upload-page-start-block-left-weblink .error {
    color: #fe5461;
    font-size: 13px;
    margin-top: 5px;
}
.upload-page-start-block-left-size {
    margin-top: 15px;
}
@media (max-width: 1024px) {
    .upload-page-start-block-left {
        width: 100%;
        padding: 0;
        padding-bottom: 32px;
        margin-bottom: 28px;
        max-width: 540px;
    }
}
.upload-page-start-block-right-rules {
    padding-left: 66px;
    max-width: 540px;
}
@media (max-width: 1024px) {
    .upload-page-start-block-right-rules {
        padding-left: 30px;
    }
}
.upload-page-start-block-right-rules-item {
    position: relative;
    margin-top: 20px;
}
.upload-page-start-block-right-rules-item > .title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #ff793f;
    margin-bottom: 4px;
}
.upload-page-start-block-right-rules-item > .text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 20px;
    color: #BDC3C7;
}
.upload-page-start-block-right-rules-item:first-child {
    margin-top: 0;
}
.upload-page-start-block-right-rules-item.good:before {
    font-family: 'remixicon' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 2rem;
    color: #27AE60;
    position: absolute;
    left: -3rem;
    top: .2rem;

}
.upload-page-start-block-right-rules-item.bad:before {
    font-family: 'remixicon' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 2rem;
    color: #fe5461;
    position: absolute;
    left: -3rem;
    top: .2rem;
}
.upload-page-uploading-error {
    padding: 10px;
    margin-bottom: 10px;
    color: #e45257;
    border: 1px solid #e45257;
    border-radius: 4px;
}
.upload-page-uploading-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
@media (max-width: 1024px) {
    .upload-page-uploading-block {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}
.upload-page-uploading-block-left {
    width: 500px;
    min-width: 500px;
}
@media (min-width: 1024px) and (max-width: 1366px) {
    .upload-page-uploading-block-left {
        min-width: 400px;
        width: 400px;
    }
}
@media (max-width: 1024px) {
    .upload-page-uploading-block-left {
        width: 100%;
        min-width: auto;
    }
}
.upload-page-uploading-block-left-fullpreview {
    padding-top: 75%;
    background-color: #fff;
    background-size: cover;
    position: relative;
}
.upload-page-uploading-block-left-fullpreview .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    color: var(--primary-text);
}
.upload-page-uploading-block-left-fullpreview .loading .icon-loading {
    font-size: 20px;
    display: block;
}
.upload-page-uploading-block-left-previews {
    margin-top: 3px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3px;
}
@media (max-width: 1024px) {
    .upload-page-uploading-block-left-previews {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .upload-page-uploading-block-left-previews {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}
.upload-page-uploading-block-left-previews-item {
    padding-top: 75%;
    background-color: #fff;
    background-size: cover;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .upload-page-uploading-block-left-previews-item {
        width: 33.33%;
        padding-top: 19%;
    }
}
.upload-page-uploading-block-left-previews-item.upload {
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #8c919d;
}
.upload-page-uploading-block-left-previews-item.upload svg {
    stroke: #8c919d;
}
.upload-page-uploading-block-left-previews-item.selected {
    position: relative;
}
.upload-page-uploading-block-left-previews-item.selected:after {
    font-family: icons;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background: #4c84ff;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 10px;
    font-size: 10px;
}
.upload-page-uploading-block-right {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 15px;
}
@media (max-width: 1024px) {
    .upload-page-uploading-block-right {
        margin-top: 16px;
        padding-left: 0;
    }
}
.upload-page-uploading-block-right-progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.upload-page-uploading-block-right-progress-block {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 10px 24px;
    background: #111;
}
.upload-page-uploading-block-right-progress-block > .name {
    font-size: 12px;
    color: #BDC3C7;
}
.upload-page-uploading-block-right-progress-block > .name span {
    color: #eee;
}
.upload-page-uploading-block-right-progress-block > .bar {
    margin-top: 5px;
    height: 10px;
    width: 100%;
}
.upload-page-uploading-block-right-progress-block > .bar > .line {
    height: 10px;
    background: #ff793f;
}
.upload-page-uploading-block-right-form {
    margin-top: 12px;
    background: #111;
    padding: 32px 24px;
}
.upload-page-uploading-block-right .bottom .btn-orange {
    min-width: 210px;
    margin-top: 24px;
}
.upload-page-uploading-block-right-tabs {
    border: 1px solid rgba(25, 35, 59, 0.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    max-height: 40px;
}
.upload-page-uploading-block-right-tabs button {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
    height: 40px;
    border: none;
    background: none;
    border-left: 1px solid rgba(25, 35, 59, 0.1);
    cursor: pointer;
    color: #8c919d;
    font-size: 24px;
}
.upload-page-uploading-block-right-tabs button:hover {
    color: #5e6576;
}
.upload-page-uploading-block-right-tabs button.selected {
    background: #4c84ff;
    color: #fff;
}
.upload-page-uploading-block-right-tabs button:first-child {
    border-left: none;
}
.upload-page-uploading-block-right .categories-error {
    margin-top: 0;
    margin-bottom: 5px;
}
.upload-page-uploading-block-right-categories {
    padding: 20px;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid rgba(25, 35, 59, 0.1);
    color: #8c919d;
    max-height: 400px;
    overflow: auto;
}
@media (max-width: 1024px) {
    .upload-page-uploading-block-right-categories {
        grid-template-columns: repeat(1, 1fr);
    }
}
.upload-page-uploading-block-right-categories.hidden {
    color: var(--secondary-text);
}
.upload-page-uploading-block-right-categories.hidden li:not(.selected) {
    cursor: no-drop;
}
.upload-page-uploading-block-right-categories li {
    padding: 5px 0;
    cursor: pointer;
}
.upload-page-uploading-block-right-categories li:before {
    width: 16px;
    height: 16px;
    border: 1px solid rgba(25, 35, 59, 0.1);
    content: "";
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: sub;
    margin-right: 10px;
}
.upload-page-uploading-block-right-categories li.selected {
    color: var(--primary-text);
}
.upload-page-uploading-block-right-categories li.selected:before {
    font-family: icons;
    color: #4c84ff;
    font-weight: 700;
    font-size: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.upload-page-error,
.upload-page-success {
    margin-top: 100px;
}
.upload-page-error-title,
.upload-page-success-title {
    text-align: center;
    color: #e45257;
    font-weight: 600;
    font-size: 21px;
}
.upload-page-error-desc,
.upload-page-success-desc {
    color: var(--primary-text);
    text-align: center;
    margin-top: 10px;
}
.upload-album-page .upload-page-uploading-block-left-previews-item {
    position: relative;
}
.upload-album-page .upload-page-uploading-block-left-previews-item.upload {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.upload-album-page .upload-page-uploading-block-right-progress {
    margin-bottom: 12px;
}
.upload-album-page .upload-page-uploading-block-right-form {
    margin-top: 0;
}
.upload-album-page .upload-page-uploading-block-right-progress-block .icon-verify {
    margin-right: 8px;
    color: #111;
    font-size: 16px;
}
.upload-album-page .upload-page-uploading-block-right-progress-block .error {
    color: #fe5461;
}
.upload-album-page .upload-page-uploading-block-right-progress-block .icon-add {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    color: #fe5461;
    font-size: 20px;
    margin-right: 12px;
}
.upload-album-page .upload-page-uploading-block-left-previews-item .icon-add {
    position: absolute;
    top: 4px;
    right: 4px;
    text-align: center;
    line-height: 20px;
    font-size: 15px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    cursor: pointer;
}
.link, .link-sm {
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #ff793f;
    cursor: pointer;
    display: initial;
    font-size: 15px;
}
.upload-page-uploading-block-right-progress-block .name span.text {
    float: right;
    color:#BDC3C7;
}

.upload-page .input {
    border: 1px solid rgba(25,35,59,.1);
    padding: 0 16px;
    color: #19233b;
    height: 40px;
    font-size: 14px;
    border-radius: 0;
}
.upload-page .textarea textarea {
    border: 1px solid rgba(25,35,59,.1);
    padding: 10px;
    outline: none;
    font-family: inherit;
    font-size: 15px;
    overflow: hidden;
    color: #19233b;
    resize: none;
    border-radius: 0;
    -webkit-appearance: none;
}
/*.upload-page form .textarea {
    resize: none;
    min-height: 120px;
    padding: 0;
    height: auto;
    background: none;
    border: none;    
}*/