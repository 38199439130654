body > .container > header {
  background: #111;
}
.header {
  justify-content: space-between;
  background: #111;
  max-width: 1323px;
  margin: 0 auto;
  padding: 20px 0;
}
.header + nav, .footer {
  background: #111;
}
.logo {
  order: initial;
}
.logo img {
  max-width: 100%;
  max-height: 50px;
}
.search {
  margin: 0 120px;
}
.social-bookmarks {
  display: none;
}
.nav-button {
  width: 100%;
  padding: 8px 0;
  margin: 0 0 0 10px;
  border: none;
  background: transparent;
  height: 38px;
  cursor: pointer;
  flex: 0;
}
.nav-button .icon {
  display: inline-block;
  height: 20px;
  width: 29px;
  position: relative;
}
.nav-button .ico-bar {
  background: #fff;
  height: 4px;
  width: 100%;
  position: absolute;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
  left: 0;
}
.nav-button .ico-bar:first-child {
  top: 0;
}
.nav-button .ico-bar:nth-child(2) {
  top: 8px;
}
.nav-button .ico-bar:nth-child(3) {
  top: 16px;
}
.nav-button-open .ico-bar {
  background: #ff792c;
  left: 4px;
}
.nav-button-open .ico-bar:first-child {
  top: -2px;
  -webkit-transform: rotate(45deg) translate(5px, 9px);
  transform: rotate(45deg) translate(5px, 9px);
}
.nav-button-open .ico-bar:nth-child(3) {
  top: 14px;
  -webkit-transform: rotate(-45deg) translate(2px, -6px);
  transform: rotate(-45deg) translate(2px, -6px);
}
.nav-button-open .ico-bar:nth-child(2) {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.search-toggle {
  height: 36px;
  width: 36px;
  background: transparent url("/static/images/search.svg") center no-repeat;
  text-indent: -1000px;
  overflow: hidden;
  cursor: pointer;
}
.search {
  justify-content: center;
  order: initial;
}
.member-links {
  flex: 0 1 auto;
  text-transform: uppercase;
  font-size: 13px;
}
.header-sort .sort {
  min-width: 0;
  margin: 0;
  line-height: 30px;
  text-align: left;
  cursor: pointer;
}
.header-sort .sort > strong {
  height: 24px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 400;
  display: block;
}
.header-sort .sort ul {
  display: block;
}
.header-sort .sort:hover {
  background: #212121;
  border-radius: 15px;
}
.header-sort .sort::before {
  transform: translateY(-50%);
}
.header-sort .sort.open {
  border-radius: 15px 15px 0 0;
}
.header-sort .sort.open::before {
  transform: translateY(-50%) rotate(180deg);
}
.header-sort .sort .sort-languages {
  min-width: 140px;
  left: 0;
}
.header-sort .sort:hover .sort-languages {
  visibility: hidden;
  opacity: 0;
}
.header-sort .sort .sort-languages.open {
  visibility: visible;
  opacity: 1;
}
.header-sort .sort ul a {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 0;
  text-align: left;
}
.primary .header-sort {
  padding: 0 10px;
  display: none;
}
.primary .header-sort .sort {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  flex: 0 !important;
  min-width: 60px;
}
.primary .header-sort .sort > strong {
  padding-right: 40px;
}
.primary .header-sort .sort::before {
  top: 40% !important;
}
.dropdown {
  position: relative;
}
.dropdown > a {
  padding-right: 15px !important;
}
.dropdown > a.open {
  color: #ff792c;
}
.dropdown > a::after {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(0);
  transform: translateY(-50%) rotate(0);
  z-index: 1;
  font-size: 6px;
  content: "\e901";
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.dropdown > a.open::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.dropdown-container {
  position: absolute;
  top: 60px !important;
  left: 0;
  min-width: 140px;
  margin: 0 0 0 -10px;
  white-space: nowrap;
  text-align: left;
}
.dropdown-container li a {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.dropdown-container.open {
  visibility: visible !important;
  opacity: 1 !important;
}
@media screen and (max-width: 479px) {
  .logo {
    max-width: 200px;
  }
}
@media screen and (max-width: 1040px) {
  .search, .member-links {
    display: none;
  }
  .search {
    margin: 0;
  }
  .search.open {
    display: flex;
    padding: 0.8rem;
  }
  .primary .header-sort {
    display: flex;
    margin: 0 -10px !important;
    padding-bottom: 10px;
    border-bottom: 2px solid #ff792c;
  }
  .primary .header-sort .menuitem {
    flex: 1;
  }
  .header + nav {
    padding-top: 0;
  }
  .dropdown-container {
    top: 54px !important;
    margin: 0;
  }
}
@media screen and (min-width: 1041px) {
  .nav-button, .search-toggle {
    display: none;
  }
  .header-sort .sort:hover {
    background: #212121;
    border-radius: 15px;
  }
  .header-sort .sort:hover {
    border-radius: 15px 15px 0 0;
  }
  .header-sort .sort:hover::before {
    transform: translateY(-50%) rotate(180deg);
  }
  .header-sort .sort:hover .sort-languages {
    visibility: visible;
    opacity: 1;
  }
  .dropdown:hover > a {
    color: #ff792c;
  }
  .dropdown:hover > a::after {
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
  }
  .dropdown:hover .dropdown-container {
    visibility: visible !important;
    opacity: 1 !important;
  }
}